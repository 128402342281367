<template>
  <edit-template class="add" @confirm="submit" :confirm-loading="loading" :data-loading="dataLoading" type="card">
    <edit-card title="教材基础信息">
      <el-form :model="form" ref="form" label-width="90rem" :rules="rules">
<!--        所属学科:select-->
        <el-form-item label="所属学科">{{ subject_name }}</el-form-item>
<!--        应用学段:radio-->
        <el-form-item label="应用学段">{{ apply_type_name }}</el-form-item>
        <el-form-item label="应用学年" prop="apply_year" v-if="form.apply_type">
          <el-checkbox-group v-model="form.apply_year">
            <el-checkbox v-for="item in gradeYearArr" :label="item.value" :key="item.value">{{ item.label }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="教材名称" prop="teach_name">
          <el-input
            v-model="form.teach_name"
            style="width: 500rem !important;"
            maxlength="30"
            show-word-limit
            placeholder="请输入"
          />
        </el-form-item>
<!--        上传教材:upload-->
        <el-form-item label="上传教材" prop="pdf_addr">
          <upload
            ref="upload"
            class="upload-demo"
            :size="500"
            :limit="1"
            :file-list="fileList"
            list-type="pdf"
            :on-change="uploadChange"
            :on-exceed="uploadExceed"
            :on-success="uploadSuccess"
            :on-remove="uploadRemove"
            :button="{text:!this.fileList.length?'点击上传':'重新上传'}"
            tip="只能上传PDF文件，且不超过500M"
            >
          </upload>
        </el-form-item>
      </el-form>
    </edit-card>
    <edit-card title="教材展示">
      <el-form :model="form" ref="form2" :rules="rules" label-width="120rem">
<!--        学生是否可见:radio-->
        <el-form-item label="学生是否可见" prop="is_display">
          <el-radio-group v-model="form.is_display">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>

      </el-form>
    </edit-card>
  </edit-template>
</template>

<script>
import EditTemplate from "@/components/pages/Edit"
import EditCard from "@/components/pages/EditCard"
import Upload from "@/components/Upload"
import { zhGet, zhPut, zh_grade_year } from '@/api'

export default {
  _config: {route: {path: "edit", meta: {title: "编辑",style:2}}},
  components: {EditTemplate , EditCard,Upload},
  data() {
    return {
      loading: false,
      dataLoading: false,
      fileList:[],
      subject_name: '',
      apply_type_name: '',
      form: {
        subject_id: null,
        apply_type: null,
        apply_year: null,
        teach_name: null,
        pdf_addr: null,
        is_display: null,
      },
      // 表单验证规则
      rules: {
        subject_id: [
          {required: true, message: '请选择所属学科', trigger: 'change'},
        ],
        apply_type: [
          {required: true, message: '请选择应用学段', trigger: 'change'},
        ],
        apply_year: [
          {required: true, message: '请选择应用学年', trigger: 'change'},
        ],
        teach_name: [
          {required: true, message: '请输入教材名称', trigger: 'blur'},
        ],
        is_display: [
          {required: true, message: '请选择学生是否可见', trigger: 'change'},
        ],
      },
      gradeYearArr:[]
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.dataLoading=true
      zhGet("/video/get-version?id="+this.$route.query.id).then(res=>{
        const { subject_name, apply_type_name, ...other } = res
        this.subject_name = subject_name
        this.apply_type_name = apply_type_name
        this.form = other
        if (res.pdf_addr){
          let name = `${subject_name}-${res.teach_name}-教材.pdf`;
          this.fileList = [{name,url:res.pdf_addr,status:"upload"}];
        }
        zh_grade_year().then(data=>{
          if (!data.length) return
          const idx = data.findIndex(item=>item.value===res.apply_type)
          this.gradeYearArr = data[idx].children
        }).finally(()=>this.dataLoading=false)
      }).catch(()=>this.dataLoading=false)
    },
    submit() {
      this.$refs.form.validate(res=>{
        this.$refs.form2.validate(res2=>{
          if (res && res2){
            this.loading = true;
            zhPut('/video/edit-version', this.form).then(()=>{
              this.$setPrevOperation("edit");
              this.$router.back();
              this.$message.success("编辑成功")
            }).finally(()=>this.loading = false)
          }
        })
      })

    },
    uploadChange(file,fileList){
      this.fileList = fileList;
    },
    uploadExceed(file,fileList){
      this.$refs.upload.reUpload(file[0],fileList[0]);
    },
    // 上传成功
    uploadSuccess(response){
      this.form.pdf_addr = response.data.file_addr;
    },
    uploadRemove(e){
      this.form.pdf_addr = "";
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
